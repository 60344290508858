import { useQuery } from '@tanstack/react-query';

import { apiClient } from 'api/ApiClient';

export const useGetMembers = (enabled = true) => {
  return useQuery({
    queryKey: ['getMembers'],
    queryFn: () => apiClient.getMembers(),
    enabled,
  });
};
